

* {
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Libre Baskerville', sans-serif;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0;
  }
  
  h5 {
    font-size: 19px;
    color: #26658c;
    font-family: 'Libre Baskerville', sans-serif;
    z-index: 1;
    position: relative;
  }
  
  h2 {
    font-size: 40px;
    line-height: 50px;

    
    position: relative;
  }
  
  .btn-view {
    background-color: #984042;
  }
  
  .lines {
    width: 84px;
    height: 2px;
    background-color: #26658c;
  
  }
  .heading-lines h2 span 
  {
  position:relative;
  display:inline-block;
  }
  p,
  li,
  a {
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }
  
  p {
    padding: 10px 0px 10px;
    margin-bottom: 0;
    color: #fff;
  }
  
  a {
    text-decoration: none;
    transition: all 0.5s ease-in-out;
    text-transform: uppercase;
  
  }
  
  a:hover {
    transition: all 0.5s ease-in-out;
  }
  
  .btn.btn-primary {
    font-family: 'Libre Baskerville', sans-serif;
    background: #4d9ee3;
    color: #ffff;
    border: none;
    border-radius: 10px;
    text-transform: capitalize;
    width: 151px;
    height: 46px;
    line-height: 30px;
    font-weight: 700;
  }
  
  .btn.btn-primary:hover {
    background-color: #26658c;
    color: white;
  }
  
  /* -----------------------------------------------------  Header  ------------------------------------------------------------------ */
  .header-main {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  
  }
  
  .upper-header {
    text-align: end;
  }
  .btn.btn-sign-up {
    width: 147px;
    height: 47px;
   /* background-color: #26658c; */
    background-color: #4d9ee3;
    color: #fff;
    border-radius: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    line-height: 30px;
    font-weight: 700;
  }
  
  .header-main .navbar.navbar-expand-lg {
    padding: 0px;
  }
  
  .header-links .nav-item {
    padding-left: 30px;
  }
  
  .header-links .nav-item .nav-link {
    font-size: 16px;
    font-family: 'Libre Baskerville', sans-serif;
    color: #696c6f;
    font-weight: 600;
  }


  .header-main .navbar-nav.header-links {
    background: #d0e8ff;

  }


  /* --------------------------------------------------  banner  ---------------------------------------------------------------------------------------- */
.banner-main {
    padding: 210px 0px 150px;
    background-color: #D0E8FF;
    background-image: url('../assets/images/bg-structure.png');
    background-repeat: no-repeat;
    background-position: right;
  }
  
  .banner h1 {
    font-size: 45px;
    line-height: 50px;
    color: #00658f;
    font-family: 'Libre Baskerville', sans-serif;
    font-weight: 700;
  }
  
  .banner p {
    padding: 28px 0px 28px;
    line-height: 30px;
    font-size: 18px;
    font-family: 'Libre Baskerville', sans-serif;
    color: #696c6f;
  }
  
  .banner .banner-text {
    padding-right: 40px;
  }
  






  /* feature section start */
  
  .feature-heading {
    text-transform: capitalize;
    font-size: 32px; /* Larger font size *color for better readability */
  }

  .feature-card {
    background: #D0E8FF;
    box-shadow: 'none';
    border: 'none';
  }

  .feature-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    transform: translateY(-5px) scale(1.05); 
    
  }
  

  .card-heading {
    color: #555;
    font-size: 21px;
    font-family: 'Libre Baskerville', sans-serif;
    font-weight: 700;
  }



  .card-icon {
    padding-left: 3%;
  }

  .card-para {
    color: #555;
    font-size: 16px;
    font-family: 'Libre Baskerville', sans-serif;
    font-weight: 300;
  }

  .features-main {
    padding-top: 100px;
  }
  
  
  
  .heading-lines h2 span .lines-box {
    position: absolute;
    bottom: -12px;
    right: 10px;
  }
  
  /* .features-card-main {
    margin-top: 40px;
  } */
  
  .features-card-main .features-card-img {
    background-color: #26658c;
    clip-path: ellipse(90% 100% at 0 50%);
    position: relative;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .features-card-img img {
    position: absolute;
    top: 35%;
    left: 18%;
  }
  
  .card-divide {
    border-radius: 10px;
    background-color: #D0E8FF;
    margin: 0;
    min-height: 170px;
  }
  
  .card-divide .card-text {
    padding: 15px 35px 15px 0px;
    position: relative;
  }
  
  .card-text .card-bg-text {
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 76px;
    color: #dbd3bd;
    font-family: 'Libre Baskerville', sans-serif;
    opacity: 0.6;
    padding: 0;
    padding-top: 5px;
  }



  

/* --------------------------------------------------------------------  about us  ------------------------------------------------------------------------------ */
.about-us-main {
  background-color: #f9f9f9;
    padding-top: 100px;
  }

  .about-us .heading-lines {
    margin-bottom: 50px;
  }
  
  .about-us-text {
    background-color: #D0E8FF;
    padding: 35px 40px;
    position: relative;
    
  }
  
  .about-us {
    margin-top: 40px;
  }
  
  .column-text {
    min-height: 300px;
    background-color: #B6EADA;
  }
  
  
  .about-img-main {
    padding: 0;
  }
  
  .about-text-main {
    padding: 0;
  }
  
  .about-us-text p {
    font-family: 'Libre Baskerville', sans-serif;
    color: #696c6f;
  font-size: 18px;
  }
  
  .about-us-text .btn.btn-primary {
    margin-top: 20px;
  }



  /* Choose Us Cards */
.choose-us-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.choose-us-card h5 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
  text-align: left;
}

.choose-us-card p {
  font-size: 1rem;
  color: #7f8c8d;
  line-height: 1.5;
  text-align: left;
  font-family: 'Libre Baskerville', sans-serif;
}

.choose-us-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}



/* login section start from here */
.glassy-login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #74ebd5 0%, #acb6e5 100%); /* Gradient background */
}

.login-glass-box {
  width: 100%;
  max-width: 350px;
  height: 400px; /* Tall and narrow look */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent for glassy effect */
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.glass-content {
  text-align: center;
  color: #fff;
}

.glass-input {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: #fff;
}

.glass-input:focus {
  border-color: #74ebd5;
  box-shadow: 0 0 10px rgba(116, 235, 213, 0.7);
}

.glass-button {
  background-color: #74ebd5;
  border: none;
  color: white;
}

.glass-button:hover {
  background-color: #60d6c9;
}




/* The container for the image */
.choose-us-img {
  position: relative;
  overflow: hidden; /* Ensures that the blurred background doesn't overflow */
}

/* The overlay that will create the blurred background color */
.choose-us-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  
  z-index: -1;
  height: 824px; /* Ensure the blur is behind the image */
}




/* Responsiveness */
@media (max-width: 767px) {
  .choose-us-card {
    padding: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .choose-us-card {
    padding: 18px;
  }
}

@media (min-width: 992px) {
  .choose-us-card {
    padding: 20px;
  }
}



  /* -----------------------------------------------------------  how-to-use-main  ------------------------------------------------------------------- */
.how-to-use-main {
    padding-top: 100px;
  }
  
  .how-use-card .how-card-1 {
    background-color: #ece7d9;
    padding: 10px;
  }
  
  .how-use-card .how-card-2 {
    background-color: #ece7d9;
    padding: 10px;
  }
  
  .how-use-card .how-card-3 {
    background-color: #ece7d9;
    padding: 10px;
  }
  
  .how-use-card .how-card-1 h5 {
    color: #fff;
  }
  
  .how-use-card .how-card-1 p {
    color: #fff;
  }
  
  .how-use-card .how-card-2 h5 {
    color: #fff;
  }
  
  .how-use-card .how-card-2 p {
    color: #fff;
  }
  
  .how-use-card .how-card-3 h5 {
    color: #fff;
  }
  
  .how-use-card .how-card-3 p {
    color: #fff;
    padding-right: 10px;
  }
  
  .how-use-card .how-card-2 .how-card-2-text {
    padding: 0;
  }
  
  .how-use-card .how-card-2 .how-card-2-img {
    padding-right: 25px;
  }
  
  .how-use-card .how-card-2 .how-card-2-text {
    padding: 0;
    text-align: end;
    padding-left: 250px;
  }
  
  .how-use-main-img {
    position: relative;
  }
  
  .how-use-main-img .how-use-img {
  max-width: 130%;
  width: 130%;
  margin-top: 100px;
  }
  .how-use-main-img .heading-lines {
  margin-top: -13px;
  margin-bottom: 12px;
  }




  /* priucing section */

  .price-main {
    padding-top: 20px;
  }
  
  .price-card-main {
    margin-top: 40px;
  }
  
  .price-card {
    padding: 50px;
    background-color: #D0E8FF;
    border-radius: 15px;
    margin: 0px 10px 0px 0px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  
  .price-card h5 {
    text-transform: uppercase;
  }
  
  .plan-heading {
    font-size: 25px;
    text-transform: capitalize;
    color: #26658c;
    font-family: 'Libre Baskerville', sans-serif;
    z-index: 1;
    position: relative;
  }
  
  .price-card h2 {
    color: #ece7d9;
    font-family: 'Libre Baskerville', sans-serif;
    font-size: 50px;
    line-height: 50px;
  }
  
  .price-card p {
    font-family: 'Libre Baskerville', sans-serif;
    color: #171515ab;
    font-Weight: 500;
  }
  
  .plan-price p {
    color: #fff;
    padding-left: 6px;
    line-height: 16px;
  }
  
  .plan-price {
    position: relative;
    left: -50px;
    background-color: #3796ECD6;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    padding: 16px 60px 16px 50px;
    display: inline-flex;
    margin: 36px 0px 20px;
  }
  
  .price-main .price-list-main {
    padding-top: 20px;
    align-items: center;
  }
  
  .price-img img {
    margin-top: 8px;
  }
  
  .price-card .price-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  
  .price-card .price-buy-btn {
    display: flex;
    justify-content: center;
  }
  
  .price-card .price-buy-btn .btn.btn-primary {
    font-family: 'Libre Baskerville', sans-serif;
    background: #4d9ee3;
    color: #ffff;
    border: none;
    border-radius: 10px;
    text-transform: capitalize;
    width: 100%;
    height: 55px;
    line-height: 39px;
    font-size: 26px;
    margin-top: 40px;
  }

  .price-card .price-buy-btn .btn.btn-primary:hover{
    background: #2487db;
    color: #ffff;
  }
  .price-card .btn.btn-sign-up {
    width: 88px;
    height: 35px;
    background-color: #26658c;
    color: #ece7d9;
    line-height: 16px;
    position: absolute;
    font-size: 18px;
    top: 0;
    right: 20px;
    text-transform: none;
    font-family: 'Libre Baskerville', sans-serif;
  }


/* how to use section */

/* .how-content1 {
  background-color: #8fd4f1;
}

.how-content2 {
  background-color: #7bcbee;
}

.how-content3 {
 // background-color: #327997;
} */

.how-content1 {
  background-color: #4d9ee382;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 500px; /* Optional: limits the width */
  margin: 0 auto; /* Center the box */
}

  /* benifits secition */
  .benifits-main{
    padding-top: 100px;
  }
  /* .benifit-card-main {
    margin-top: 40px;
  } */
  /* .benifit-card-main-2 {
    margin-top: 25px;
  } */
  .benifit-card {
    background-color: #D0E8FF;
    padding: 22px;
    border-radius: 10px;
  }
  .benifit-card p {
    font-family: 'Libre Baskerville', sans-serif;
    color: #41566a;
    font-size: 18px;
  }
  
  
  



  /* ------------------------------------------------------------------  faq  ----------------------------------------------------------------------------- */
.faq-main{
    padding-top: 100px;
  }
  .faq-card .accordion-item {
  margin-bottom: 20px;
  }
  .faq-card .accordion-item:first-child{
    margin-top: 0px;
  }
  
  .faq-card-main{
    margin-top: 40px;
  }
  
  .faq-card-main .faq-card .accordion-item h2 .accordion-button {
  background-color: #00658f;
  color: #fff;
  border-radius: 0;
  justify-content: space-between;
  }
  
  .faq-card-main .faq-card h2 button b {
  font-size: 20px;
  text-transform: uppercase;
   font-family: 'Libre Baskerville', sans-serif;
  line-height: 1.2;
  display: block;
  padding: 10px 0px;
  }
  .faq-card .accordion-body {
    background-color: #ece7d9;
  }
  .faq-card .accordion {
    border-radius: 0px !important;
  }
  
  .faq-card-main .faq-card .accordion-item h2 .accordion-button:after 
  {
  color:#fff;
  fill:#fff;
  }
  .faq-card-main .faq-card .accordion-item h2 .accordion-button::after 
  {
  display:none;
  }
  
  .faq-card-main .faq-card .accordion-item h2 .accordion-button .img-fluid {
  width: 28px;
  filter: brightness(0) invert(1);
  transition:all 0.5s ease;
  }
  .faq-card-main .faq-card .accordion-item h2 .accordion-button[aria-expanded="true"] .img-fluid 
  {
  transform:rotate(180deg);
    transition:all 0.5s ease;
  }
  
  
  

  /* footer */
  .footer-main {
    padding-top: 62px;
  }
  .footer{
    text-align: center;
  }
  .footer-icons img {
    margin: 0px 10px 0px 10px;
    cursor: pointer;
  }
  .footer-icons{
    padding-bottom: 20px;
  }
  .copyright p{
    font-family: 'Libre Baskerville', sans-serif;
  }
  .footer-link {
    display: flex;
    justify-content: center;
    padding: 20px 0px 20px;
  
  }
  .footer-link .nav-item {
    list-style: none;
  }
  
  .footer-link .nav-item .nav-link {
    margin-left: 50px;
    font-size: 18px;
    font-family: 'Libre Baskerville', sans-serif;
    font-weight: 600;
    color: #696c6f;
  }
  .footer .footer-text {
  padding: 20px 17% 20px;
  }
  
  .copyright{
    padding: 10px 0px 10px;
    border-top: 2px solid #1c4762;
  }




  /* -------------------------------------------------------------- Log in page ----------------------------------------------------------------------------------- */

  .welcome-back {
    width: 781px;
    background: linear-gradient(135deg, #96dbf08f 0%, #c6d5e8 100%);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(67, 162, 200, 0.55) 0px 3px 6px;
    padding: 40px;
    margin: auto;
    max-width: 100%;
  }

  
.heading-lines {
  margin-bottom: 30px;
}

  

.heading-lines h2 {
  font-size: 32px;
  font-weight: bold;
  color: #333;
}

.input-group-text {
  padding: 0.375rem 0.75rem;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-left: 8px;
}

.text-danger {
  font-size: 0.875rem;
  color: #dc3545;
}
  
  .welcome-back h2 {
    font-size: 40px;
    line-height: 50px;
    color: #26658c;
    text-transform: uppercase;
    position: relative;
    text-align: center;
  }
  .welcome-back .heading-lines h2 span .lines-box {
    position: absolute;
    /* bottom: -60px; */
    left: 0;
  }
  .welcome-back .btn.btn-primary {
    margin-top: 100px;
  }
  .login-form form input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: #fff;
  }
  .keep-login {
    display: inline-flex;
  }
  .checkin-main {
    display: flex;
    gap: 14px;
    margin-top: 20px;
  }
  .welcome-back .login-form .checkin {
    width: auto;
  }
  .login-form form .security {
    margin-top: 37px;
  }
  .login-form form label {
    font-size: 18px;
    
  }
  .login-form {
    padding-bottom: 0px;
  }
  
  /* --------------------------------------------------------------- popup ----------------------------------------------------------------------------------- */
  .popup {
  background-color: #ece7d9;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 45px;
  width: 80%;
  margin: auto;
  }
  .popup .popup-card {
  padding: 30px 0px 30px;
  }
  .popup-card.active {
  border: 2px solid c24848;
  background-color: white; 
  color: #c24848; 
  }
  
  .price-button .cancel {
  font-family: 'Libre Baskerville', sans-serif;
  background: transparent;
  color: #c24848;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  width: 151px;
  height: 46px;
  line-height: 30px;
  border: 2px solid #c24848;
  }
  .price-button .cancel:hover {
  font-family: 'Libre Baskerville', sans-serif;
  background: #c24848;
  color: #fff;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  width: 151px;
  height: 46px;
  line-height: 30px;
  border: 2px solid #c24848;
  }
  .popup .heading-lines h2 span .lines-box {
  position: static;
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  }
  .popup-main .price-button {
  display: flex;
  gap: 20px;
  justify-content: center;
  }
  .popup .popup-card .price-card {
  background-color: #fff;
  text-align: center;
  }
  .popup-img {
  background-color: #ece7d9;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
  }
  .popup-img {
  background-color: #ece7d9;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  }
  .popup-img img {
  width: 65%;
  margin: auto;
  }
  .popup .popup-card .price-card h2 {
  color: #1c4762;
  text-align: center;
  padding-top: 30px;
  }
  .popup .popup-card h5 {
  text-align: center;
  color: #26658c;
  padding-bottom: 15px;
  }
  
  .popup .login-form {
  width: 500px;
  max-width: 100%;
  margin-bottom: 30px;
  background-color: rgba(255,255, 255, 0.3);
  padding: 30px;
  }
  .popup .login-form form .security {
  margin-top: 20px;
  }
  .mail-select {
  width: 35%;
  position: absolute;
  top: 0px;
  right: 0;
  height: 45px;
  }
  .automail {
  position: relative;
  }
  
  
  
  #GFG .heading-lines h2 span {
  position: relative;
  display: inline-block;
  }
  #GFG .heading-lines h2 span .lines-box {
  position: static;
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  }
  #GFG .heading-lines h2 span .lines-box {
  position: absolute;
  bottom: -12px;
  right: 10px;
  }
  #GFG .lines {
  width: 84px;
  height: 2px;
  background-color: #26658c;
  }
  
  #GFG .heading-lines.text-center {
  margin-bottom: 50px;
  }
  
  #GFG .modal-dialog.modal-lg {
    width: 892px;
    max-width: 100%;
  }
  
  #GFG .modal-dialog.modal-lg .modal-content {
  background: #D0E8FF;
  border-radius: 0;
  }
  
  #GFG .modal-dialog.modal-lg .modal-content .popup-card {
    border-radius: 20px !important;
    padding: 50px;
    background-color: #92bbe6;
    margin: 0px 10px 0px 0px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  
  #GFG .modal-dialog.modal-lg .modal-content .popup-card img {
  background:#D0E8FF;
  padding: 15px;
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 100%;
  margin: 10px 0px;
  border: 2px solid #fff;
  }
  
  #GFG .modal-dialog.modal-lg .modal-content .popup-card h4 {
  font-family: 'Libre Baskerville', sans-serif;
  font-size: 42px;
  line-height: 46px;
  color: #1c4762;
  text-align: center;
  padding-top: 10px;
  }
  #GFG .modal-dialog.modal-lg .modal-content .popup-card h3 {
  text-align: center;
  color: #26658c;
  padding-bottom: 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  min-height:60px;
  }
  
  #GFG .modal-dialog.modal-lg .modal-content .popup-card .btn.popup-btn {
    font-family: 'Libre Baskerville', sans-serif;
  background: #4d9ee3;
  color: #ffff;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  width: 151px;
  height: 46px;
  line-height: 30px;
  }
  
  #GFG .modal-dialog.modal-lg .modal-content .popup-card.active {
    background-color: #ffff;
    border: 2px solid #00658f;
  }
  
  #GFG .modal-dialog.modal-lg .modal-content .popup-card p {
  color: #000;
  }
  
  
  #GFG .modal-dialog.modal-lg .modal-content .popup-card .btn.popup-btn {
  margin-top: 20px;
  }
  #GFG .account-container {
    margin: 41px auto 30px !important;
    width: 500px;
    max-width: 100%;
    background-color: rgba(255,255, 255, 0.3);
    padding: 30px;
  }
  
  #GFG .account-container h2 {
  margin-bottom: 20px;
  }
  
  #GFG .account-container .input-group.username-div .input-group select#domain {
  position: absolute;
  z-index: 8888;
  top: 1px;
  right: 2px;
  bottom: 1px;
  width: 145px;
  border-radius: 0;
  }
  #GFG .account-container h2 {
    font-size: 1.75rem;
    text-align: center;
  }
  #GFG .popup-footer {
    float: none !important;
    justify-content: center;
    padding: 10px 0px 0px;
  }
  #GFG .account-container .input-group input {
    border: 1px solid #ddd;
  }
  #GFG .account-container .input-group h6 {
    margin-top: 15px;
    font-size: 14px;
  }
  #GFG  .popup-footer .btn.btn-secondary {
    font-family: 'Libre Baskerville', sans-serif;
  background: transparent;
  color: #c24848;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  width: 151px;
  height: 46px;
  line-height: 30px;
  border: 2px solid #c24848;
  }
  
  #GFG  .popup-footer #cancel {
    font-family: 'Libre Baskerville', sans-serif;
  background:#4d9ee3;
  color: #ffff;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  width: 151px;
  height: 46px;
  line-height: 30px;
  margin-left: 5px;
  }
  
  .welcome-back .btn.btn-primary {
    margin-top: 30px;
  }
  #GFG .modal-dialog.modal-lg .modal-content .modal-body {
    padding: 45px;
  }
  .dashboard-contain .desh-main .flex-column.align-items-md-center .fs-5.d-none.d-sm-inline > img {
  width: 120px;
  }
  .dashboard-contain .desh-main {
  background: #D0E8FF;
  padding: 15px 15px !important;
  }
  
  .dashboard-contain .dashboard {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
  border: 0 !important;
  padding: 20px;
  margin-top: 30px;
  }
  
  .pop_up.btn.btn-contained {
    font-family: 'Libre Baskerville', sans-serif;
  background: #4d9ee3;
  color: #ffff;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  width: 151px;
  height: 46px;
  line-height: 30px;
  }
  
  .dashboard-contain .dashboard table form #renew {
  border: 1px solid #c24848;
  color: gray;
  }
  .dashboard-contain .dashboard .header-card .dash-btn.btn.btn-contained {
  background: #00658f;
  color: #fff !important;
  }
  
  .dashboard-contain .dashboard .header-card .dash-btn.btn.btn-contained .link {
  color: #fff;
  }
  .dashboard-contain .dashboard .dashboard-main .server-search {
  padding: 8px 15px;
  font-size: 14px;
  }
  
  #new {
    background: #4d9ee3;
    color: #ffff;
    font-family: 'Libre Baskerville', sans-serif;
  }
  
  

  /* invoice page styling */

  .invoice_para {
    color: #0C5171;
    font-weight: 300;
    font-size: 17px;
    font-family: 'Libre Baskerville', sans-serif;
  }