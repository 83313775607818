

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: manrope;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
}

h5 {
  font-size: 19px;
  color: #26658c;
  font-family: barlow-semi-bold;
  z-index: 1;
  position: relative;
}

h2 {
  font-size: 28.4px;
  font-weight: bold;
  color: #333;
  line-height: 50px;
  text-transform: '';
  position: relative;
}

.btn-view {
  background-color: #984042;
}

.lines {
  width: 84px;
  height: 2px;
  background-color: #26658c;

}
.heading-lines h2 span 
{
position:relative;
display:inline-block;
}
p,
li,
a {
  font-size: 16px;
  line-height: 24px;
  color: #000;
}

p {
  padding: 10px 0px 10px;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  text-transform: uppercase;

}

a:hover {
  transition: all 0.5s ease-in-out;
}

.btn.btn-primary {
  font-family: barlow-bold;
  background: linear-gradient(to right, #26658c, #984042);
  color: #ffff;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  width: 151px;
  height: 46px;
  line-height: 30px;
}


/* -----------------------------------------------------  Header  ------------------------------------------------------------------ */
.header-main {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

}

.upper-header {
  text-align: end;
}
.btn.btn-sign-up {
  width: 147px;
  height: 47px;
  background-color: #26658c;
  color: #fff;
  border-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  line-height: 30px;
}

.header-main .navbar.navbar-expand-lg {
  padding: 0px;
}

.header-links .nav-item {
  padding-left: 30px;
}

.header-links .nav-item .nav-link {
  font-size: 16px;
}


/* --------------------------------------------------  banner  ---------------------------------------------------------------------------------------- */
.banner-main {
  padding: 210px 0px 150px;
  background-color: #ece7d9;
  background-image: url('../assets/images/bg-structure.png');
  background-repeat: no-repeat;
  background-position: right;
}

.banner h1 {
  font-size: 46px;
  line-height: 50px;
  color: #00658f;
  font-family: barlow-bold;
}

.banner p {
  padding: 28px 0px 28px;
  line-height: 30px;
  font-size: 18px;
}

.banner .banner-text {
  padding-right: 40px;
}


/* -----------------------------------------------  features  ------------------------------------------------------------------------------------------------------ */
.features-main {
  padding-top: 100px;
}



.heading-lines h2 span .lines-box {
  position: absolute;
  bottom: -12px;
  right: 10px;
}

/* .features-card-main {
  margin-top: 40px;
} */

.features-card-main .features-card-img {
  background-color: #00658f;
  clip-path: ellipse(90% 100% at 0 50%);
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.features-card-img img {
  position: absolute;
  top: 35%;
  left: 18%;
}

.card-divide {
  border-radius: 10px;
  background-color: #f1ecde;
  margin: 0;
  min-height: 170px;
}

.card-divide .card-text {
  padding: 15px 35px 15px 0px;
  position: relative;
}

.card-text .card-bg-text {
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 76px;
  color: #dbd3bd;
  font-family: barlow-bold;
  opacity: 0.6;
  padding: 0;
  padding-top: 5px;
}


/* --------------------------------------------------------------------  about us  ------------------------------------------------------------------------------ */
.about-us-main {
  padding-top: 100px;
}

.about-us-text {
  background-color: #ece7d9;
  padding: 35px 40px;
  
  position: relative;

}

.about-us {
  margin-top: 40px;
}

.column-text {
  min-height: 300px;
  background-color: #dbd3bd;
}

.about-img-main {
  padding: 0;
}

.about-text-main {
  padding: 0;
}

.about-us-text p {
  font-family: barlow-medium;
}

.about-us-text .btn.btn-primary {
  margin-top: 20px;
}


/* -----------------------------------------------------------------  choose-us -------------------------------------------------------------------------- */
.choose-us-main {
  padding-top: 100px;
}

.choose-us {
  margin-top: 40px;
}

.choose-us-main .choose-us-card {
  text-align: end;
  margin-top: 24px;
  padding-right: 40px;
}

.choose-us-img {
  position: relative;
}

.choose-card-icons {
  position: absolute;
  top: 30px;
}

.choose-card-icons .choose-icon {
  background-color: #f1ecde;
  padding: 18px;
  margin-bottom: 40px;
  border-radius: 50%;
  border: 2px solid #26658c;
  position: relative;
  left: 40px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.choose-us-lins {
  position: relative;
  left: 0px;
  width: 50px;
  height: 8px;
  background-color: #f1ecde;
  top: 48px;
  border: 1px solid #26658c;
}



/* -----------------------------------------------------------  how-to-use-main  ------------------------------------------------------------------- */
.how-to-use-main {
  padding-top: 100px;
}

.how-use-card .how-card-1 {
  background-color: #26658c;
  padding: 10px;
}

.how-use-card .how-card-2 {
  background-color: #215778;
  padding: 10px;
}

.how-use-card .how-card-3 {
  background-color: #1c4762;
  padding: 10px;
}

.how-use-card .how-card-1 h5 {
  color: #fff;
}

.how-use-card .how-card-1 p {
  color: #fff;
}

.how-use-card .how-card-2 h5 {
  color: #fff;
}

.how-use-card .how-card-2 p {
  color: #fff;
}

.how-use-card .how-card-3 h5 {
  color: #fff;
}

.how-use-card .how-card-3 p {
  color: #fff;
  padding-right: 10px;
}

.how-use-card .how-card-2 .how-card-2-text {
  padding: 0;
}

.how-use-card .how-card-2 .how-card-2-img {
  padding-right: 25px;
}

.how-use-card .how-card-2 .how-card-2-text {
  padding: 0;
  text-align: end;
  padding-left: 250px;
}

.how-use-main-img {
  position: relative;
}

.how-use-main-img .how-use-img {
max-width: 130%;
width: 130%;
margin-top: 100px;
}
.how-use-main-img .heading-lines {
margin-top: -13px;
margin-bottom: 12px;
}

/* --------------------------------------------------------------   price-main   ------------------------------------------------------------------------- */
.price-main {
  padding-top: 100px;
}

.price-card-main {
  margin-top: 40px;
}

.price-card {
  padding: 50px;
  background-color: #dbd3bd;
  border-radius: 15px;
  margin: 0px 10px 0px 0px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.price-card h5 {
  text-transform: uppercase;
}

.plan-heading {
  font-size: 25px;
  text-transform: uppercase;
  color: #26658c;
  font-family: barlow-semi-bold;
  z-index: 1;
  position: relative;
}

.price-card h2 {
  color: #ece7d9;
  font-family: barlow-bold;
  font-size: 50px;
  line-height: 50px;
}

.price-card p {
  color: #00658f;
}

.plan-price p {
  color: #fff;
  padding-left: 6px;
  line-height: 16px;
}

.plan-price {
  position: relative;
  left: -50px;
  background-color: #26658c;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  padding: 16px 60px 16px 50px;
  display: inline-flex;
  margin: 36px 0px 20px;
}

.price-main .price-list-main {
  padding-top: 20px;
  align-items: center;
}

.price-img img {
  margin-top: 8px;
}

.price-card .price-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
}

.price-card .price-buy-btn {
  display: flex;
  justify-content: center;
}

.price-card .price-buy-btn .btn.btn-primary {
  font-family: barlow-semi-bold;
  background: linear-gradient(to right, #26658c, #984042);
  color: #ffff;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  width: 100%;
  height: 55px;
  line-height: 39px;
  font-size: 30px;
  margin-top: 40px;
}
.price-card .btn.btn-sign-up {
  width: 88px;
  height: 35px;
  background-color: #26658c;
  color: #ece7d9;
  line-height: 16px;
  position: absolute;
  font-size: 18px;
  top: 0;
  right: 20px;
  text-transform: none;
  font-family: barlow-semi-bold;
}



/* --------------------------------------------------------------  benifits-main  ----------------------------------------------------------------------------- */
.benifits-main{
  padding-top: 100px;
}
/* .benifit-card-main {
  margin-top: 40px;
} */
/* .benifit-card-main-2 {
  margin-top: 25px;
} */
.benifit-card {
  background-color: #ece7d9;
  padding: 22px;
  border-radius: 10px;
}
.benifit-card p {
  color: #1c4762;
}


/* ------------------------------------------------------------------  faq  ----------------------------------------------------------------------------- */
.faq-main{
  padding-top: 100px;
}
.faq-card .accordion-item {
margin-bottom: 20px;
}
.faq-card .accordion-item:first-child{
  margin-top: 0px;
}

.faq-card-main{
  margin-top: 40px;
}

.faq-card-main .faq-card .accordion-item h2 .accordion-button {
background-color: #00658f;
color: #fff;
border-radius: 0;
justify-content: space-between;
}

.faq-card-main .faq-card h2 button b {
font-size: 20px;
text-transform: uppercase;
font-family: barlow-medium;
line-height: 1.2;
display: block;
padding: 10px 0px;
}
.faq-card .accordion-body {
  background-color: #ece7d9;
}
.faq-card .accordion {
  border-radius: 0px !important;
}

.faq-card-main .faq-card .accordion-item h2 .accordion-button:after 
{
color:#fff;
fill:#fff;
}
.faq-card-main .faq-card .accordion-item h2 .accordion-button::after 
{
display:none;
}

.faq-card-main .faq-card .accordion-item h2 .accordion-button .img-fluid {
width: 28px;
filter: brightness(0) invert(1);
transition:all 0.5s ease;
}
.faq-card-main .faq-card .accordion-item h2 .accordion-button[aria-expanded="true"] .img-fluid 
{
transform:rotate(180deg);
  transition:all 0.5s ease;
}
/* ---------------------------------------------------------- footer ---------------------------------------------------------------------- */
.footer-main {
  padding-top: 62px;
}
.footer{
  text-align: center;
}
.footer-icons img {
  margin: 0px 10px 0px 10px;
  cursor: pointer;
}
.footer-icons{
  padding-bottom: 20px;
}
.copyright p{
  font-family: barlow-semi-bold;
}
.footer-link {
  display: flex;
  justify-content: center;
  padding: 20px 0px 20px;

}
.footer-link .nav-item {
  list-style: none;
}

.footer-link .nav-item .nav-link {
  margin-left: 50px;
  font-size: 20px;
  font-family: barlow-semi-bold;
}
.footer .footer-text {
padding: 20px 17% 20px;
}

.copyright{
  padding: 10px 0px 10px;
  border-top: 2px solid #1c4762;
}
/* -------------------------------------------------------------- Log in page ----------------------------------------------------------------------------------- */

.welcome-back {
  width: 781px;
  background-color: #fcf9f261;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 40px;
  margin: auto;
  max-width: 100%;
}


.welcome-back h2 {
  font-size: 40px;
  line-height: 50px;
  color: #26658c;
  text-transform: uppercase;
  position: relative;
  text-align: left;
}
.welcome-back .heading-lines h2 span .lines-box {
  position: absolute;
  /* bottom: -60px; */
  left: 0;
}
.welcome-back .btn.btn-primary {
  margin-top: 100px;
}
.login-form form input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
}
.keep-login {
  display: inline-flex;
}
.checkin-main {
  display: flex;
  gap: 14px;
  margin-top: 20px;
}
.welcome-back .login-form .checkin {
  width: auto;
}
.login-form form .security {
  margin-top: 37px;
}
.login-form form label {
  font-size: 18px;
  
}
.login-form {
  padding-bottom: 0px;
}

/* --------------------------------------------------------------- popup ----------------------------------------------------------------------------------- */
.popup {
background-color: #ece7d9;
box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
padding: 45px;
width: 80%;
margin: auto;
}
.popup .popup-card {
padding: 30px 0px 30px;
}
.popup-card.active {
border: 2px solid c24848;
background-color: white; 
color: #c24848; 
}

.price-button .cancel {
font-family: barlow-bold;
background: transparent;
color: #c24848;
border: none;
border-radius: 10px;
text-transform: uppercase;
width: 151px;
height: 46px;
line-height: 30px;
border: 2px solid #c24848;
}
.price-button .cancel:hover {
font-family: barlow-bold;
background: #c24848;
color: #fff;
border: none;
border-radius: 10px;
text-transform: uppercase;
width: 151px;
height: 46px;
line-height: 30px;
border: 2px solid #c24848;
}
.popup .heading-lines h2 span .lines-box {
position: static;
display: grid;
justify-content: center;
align-content: center;
text-align: center;
}
.popup-main .price-button {
display: flex;
gap: 20px;
justify-content: center;
}
.popup .popup-card .price-card {
background-color: #fff;
text-align: center;
}
.popup-img {
background-color: #ece7d9;
width: 100px;
height: 100px;
border-radius: 50%;
margin: auto;
}
.popup-img {
background-color: #ece7d9;
width: 100px;
height: 100px;
border-radius: 50%;
display: flex;
}
.popup-img img {
width: 65%;
margin: auto;
}
.popup .popup-card .price-card h2 {
color: #1c4762;
text-align: center;
padding-top: 30px;
}
.popup .popup-card h5 {
text-align: center;
color: #26658c;
padding-bottom: 15px;
}

.popup .login-form {
width: 500px;
max-width: 100%;
margin-bottom: 30px;
background-color: rgba(255,255, 255, 0.3);
padding: 30px;
}
.popup .login-form form .security {
margin-top: 20px;
}
.mail-select {
width: 35%;
position: absolute;
top: 0px;
right: 0;
height: 45px;
}
.automail {
position: relative;
}



#GFG .heading-lines h2 span {
position: relative;
display: inline-block;
}
#GFG .heading-lines h2 span .lines-box {
position: static;
display: grid;
justify-content: center;
align-content: center;
text-align: center;
}
#GFG .heading-lines h2 span .lines-box {
position: absolute;
bottom: -12px;
right: 10px;
}
#GFG .lines {
width: 84px;
height: 2px;
background-color: #26658c;
}

#GFG .heading-lines.text-center {
margin-bottom: 50px;
}

#GFG .modal-dialog.modal-lg {
  width: 892px;
  max-width: 100%;
}

#GFG .modal-dialog.modal-lg .modal-content {
background: #ece7d9;
border-radius: 0;
}

#GFG .modal-dialog.modal-lg .modal-content .popup-card {
  border-radius: 20px !important;
  padding: 50px;
  background-color: #dbd3bd;
  margin: 0px 10px 0px 0px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

#GFG .modal-dialog.modal-lg .modal-content .popup-card img {
background: #ece7d9;
padding: 15px;
width: 100px;
height: 100px;
object-fit: contain;
border-radius: 100%;
margin: 10px 0px;
border: 2px solid #fff;
}

#GFG .modal-dialog.modal-lg .modal-content .popup-card h4 {
font-family: barlow-bold;
font-size: 42px;
line-height: 46px;
color: #1c4762;
text-align: center;
padding-top: 10px;
}
#GFG .modal-dialog.modal-lg .modal-content .popup-card h3 {
text-align: center;
color: #26658c;
padding-bottom: 15px;
font-size: 18px;
text-transform: uppercase;
font-weight: 700;
min-height:60px;
}

#GFG .modal-dialog.modal-lg .modal-content .popup-card .btn.popup-btn {
font-family: barlow-bold;
background: linear-gradient(to right, #26658c, #984042);
color: #ffff;
border: none;
border-radius: 10px;
text-transform: uppercase;
width: 151px;
height: 46px;
line-height: 30px;
}

#GFG .modal-dialog.modal-lg .modal-content .popup-card.active {
  background-color: #ffff;
  border: 2px solid #00658f;
}

#GFG .modal-dialog.modal-lg .modal-content .popup-card p {
color: #000;
}


#GFG .modal-dialog.modal-lg .modal-content .popup-card .btn.popup-btn {
margin-top: 20px;
}
#GFG .account-container {
  margin: 41px auto 30px !important;
  width: 500px;
  max-width: 100%;
  background-color: rgba(255,255, 255, 0.3);
  padding: 30px;
}

#GFG .account-container h2 {
margin-bottom: 20px;
}

#GFG .account-container .input-group.username-div .input-group select#domain {
position: absolute;
z-index: 8888;
top: 1px;
right: 2px;
bottom: 1px;
width: 145px;
border-radius: 0;
}
#GFG .account-container h2 {
  font-size: 1.75rem;
  text-align: center;
}
#GFG .popup-footer {
  float: none !important;
  justify-content: center;
  padding: 10px 0px 0px;
}
#GFG .account-container .input-group input {
  border: 1px solid #ddd;
}
#GFG .account-container .input-group h6 {
  margin-top: 15px;
  font-size: 14px;
}
#GFG  .popup-footer .btn.btn-secondary {
font-family: barlow-bold;
background: transparent;
color: #c24848;
border: none;
border-radius: 10px;
text-transform: uppercase;
width: 151px;
height: 46px;
line-height: 30px;
border: 2px solid #c24848;
}

#GFG  .popup-footer #cancel {
font-family: barlow-bold;
background: linear-gradient(to right, #26658c, #984042);
color: #ffff;
border: none;
border-radius: 10px;
text-transform: uppercase;
width: 151px;
height: 46px;
line-height: 30px;
margin-left: 5px;
}

.welcome-back .btn.btn-primary {
  margin-top: 30px;
}
#GFG .modal-dialog.modal-lg .modal-content .modal-body {
  padding: 45px;
}
.dashboard-contain .desh-main .flex-column.align-items-md-center .fs-5.d-none.d-sm-inline > img {
width: 120px;
}
.dashboard-contain .desh-main {
background: #ece7d9;
padding: 15px 15px !important;
}

.dashboard-contain .dashboard {
background-color: #fff;
border-radius: 20px;
box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
border: 0 !important;
padding: 20px;
margin-top: 30px;
}

.pop_up.btn.btn-contained {
font-family: barlow-bold;
background: linear-gradient(to right, #26658c, #984042);
color: #ffff;
border: none;
border-radius: 10px;
text-transform: uppercase;
width: 151px;
height: 46px;
line-height: 30px;
}

.dashboard-contain .dashboard table form #renew {
border: 1px solid #c24848;
color: gray;
}
.dashboard-contain .dashboard .header-card .dash-btn.btn.btn-contained {
background: #00658f;
color: #fff !important;
}

.dashboard-contain .dashboard .header-card .dash-btn.btn.btn-contained .link {
color: #fff;
}
.dashboard-contain .dashboard .dashboard-main .server-search {
padding: 8px 15px;
font-size: 14px;
}

#new {
  background: linear-gradient(to right, #26658c, #984042);
  color: #ffff;
  font-family: barlow-bold;
}


/* --------------------------------------------------------  Responsive  -------------------------------------------------------------------------------- */

@media (max-width:1400px){
.choose-us-img .img-fluid {
height: 100%;
object-fit: cover;
}
}


@media (max-width:1200px){

  .banner-main {
      padding: 200px 0px 100px;
  }
  .banner h1 {
      font-size: 40px;
      line-height: 40px;
  }
  .banner p {
      padding: 20px 0px 20px;
      line-height: 23px;
      font-size: 16px;
    }
    .banner-main {
      background-position: center;
    }
    h5 {
      font-size: 20px; 
    }

    .card-divide .card-text {
      padding: 15px 20px 15px 15px;
    }
   .card-divide {
  min-height: 180px;
}
    .features-card-main .features-card-img {
      clip-path: ellipse(100% 100% at 0 50%);
    }
    .about-us-text {
      padding: 20px 20px;
     
    }
    p, li, a {
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

.choose-card-icons .choose-icon {
  padding: 15px 6px 14px 15px;
  margin-bottom: 25px;
}
.choose-card-icons .choose-icon img {
  width: 70%;
}
.choose-us-lins {
  top: 33px;
}
.choose-us-main .choose-us-card {
  text-align: end;
  margin-top: 15px;
  padding-right: 0px;
}
.choose-us-main .choose-us-card h5{
  font-size: 18px;
}
.choose-card-icons .choose-icon {
  left: 25px;
}
.choose-card-icons {
  position: absolute;
  top: 25px;
}

.how-use-card .how-card-2 .how-card-2-text {
  padding-left: 200px;
}
.how-use-card .how-card-2 .how-card-2-img {
  padding-right: 18px;
  margin-top: 8px;
  padding-left: 17px;
}


.price-img {
  padding: 0px;
}
.price-img img {
  margin-top: 0px;
}
.benifit-card {
  min-height: 144px;
}
.faq-card-main .faq-card h2 button b {
  line-height: 25px;
}
.about-img-main .img-fluid {
margin-left: 0;
margin-top: 10px;
}
.how-use-main-img .how-use-img {
max-width: 160%;
width: 160%;
margin-top: 12px;
}
.how-use-card .use-img {
margin-bottom: 15px;
}
.welcome-back{
width: 100%;
}
.welcome-back .btn.btn-primary {
margin-top: 80px;
}

}





@media (max-width:992px){
.card-text .card-bg-text 
{
top:10px;
}
  .banner-main {
      padding: 160px 0px 100px;
    }
    .card-divide {
      min-height: 180px;
    }
    .features-card-main .col-xs-12.col-md-4 {
      width: 50%;
    }
   .banner h1 {
  font-size: 30px;
  line-height: 40px;
}
    .banner .banner-text {
      padding-right: 0px;
    }
    .about-us-text {
      padding: 15px 15px;
      border-bottom: 0px;
      top: 0px;
    }
.about-img-main {
  background: #1782a5;
  margin-top: 28px;
}
    .about-us-text p {
      font-family: barlow;
      line-height: 22px;
    }
    /* .choose-us .col-xs-12.col-sm-12.col-md-4.col-lg-6 {
      width: 50%;
    } */
    .choose-card-icons .choose-icon {
      padding: 15px 6px 14px 15px;
      margin-bottom: 18px;
    }
    .choose-us-card p {
      font-size: 14px;
      line-height: 20px;
    }
    .choose-us-main .choose-us-card {
      margin-top: 11px;
    }
   .choose-card-icons {
  position: absolute;
  top: 19px;
  display: none;
}
    .how-to-use-main .col-xs-12.col-sm-12.col-md-4.how-use-main-img {
      width: 100%;
      text-align: center;
    }
 .how-use-main-img .how-use-img {
  max-width: 100%;
  width: auto;
  margin-top: 25px;
  margin-bottom: -150px;
}
    .how-to-use-main .col-xs-12.col-sm-12.col-md-8 {
      width: 100%;
    }
    .row.price-card-main .col-xs-12.col-sm-12.col-md-4 {
      width: 50%;
    }
    .last-price-card {
      margin: auto;
      padding-top: 25px;
    }
    .row.benifit-card-main .col-xs-12.col-sm-12.col-md-4 {
      width: 50%;
    }
    .footer-link .nav-item .nav-link {
      margin-left: 30px;
      font-size: 18px;
      font-family: barlow-semi-bold;
    }
    .how-use-card .how-card-1 .col-xs-3.col-sm-5 {
      width: 100%;
      text-align: center !important;
    }
    .row.how-card-1 .col-xs-9.col-sm-7 {
      width: 100%;
      text-align: center;
      padding: 10px;
    }
   
    .how-card-2 .how-card-2-text {
      width: 100%;
      padding-left: 0px !important;
      text-align: center !important;
    }
    .col-xs-3.col-sm-2.text-end.how-card-2-img {
      width: 100%;
      text-align: center !important;
    }
    .how-card-3 .col-xs-3.col-sm-5 {
      width: 100%;
      text-align: center !important;
    }
    .how-card-3 .col-xs-9.col-sm-7 {
      width: 100%;
      text-align: center;
      padding: 10px;
    }
    .how-use-card .how-card-1 {
      background-color: #26658c;
      padding: 30px;
    }
    .how-use-card .how-card-2 {
      padding: 30px;
    }
    .how-use-card .how-card-3 {
      padding: 30px;
    }
.how-use-card .how-card-1 {
  background-color: #26658c;
  padding: 170px 30px 30px;
}
.how-use-card .how-card-2
{
  flex-direction: column-reverse;
}
.how-use-card .how-card-2 .how-card-2-img {
margin-bottom: 20px;
}
.banner-main .row {
align-items: center;
}
.header-main .navbar-nav.header-links {
background: #ece7d9;
box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}
.faq-card-main .faq-card h2 button b {
  line-height: 25px;
  font-size: 16px;
}
.about-img-main .img-fluid {
margin-top: -28px;
}
}



@media (max-width:768px)
{
.banner-main {
  padding: 160px 0px 100px;
  background-position: bottom;
}
.card-divide {
  min-height: auto;
  flex-wrap: wrap;
  align-items: center;
}


.features-card-main .features-card-img {
  background-color: #00658f;
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 100px;
  border-radius: 0;
  border-radius: 100%;
  height: 100px;
  margin: 30px auto 10px;
clip-path: none;
  line-height: 84px;
}
.features-card-main .card-text {
text-align: center;
}

.features-card-img img {
  position: relative;
  padding: 15px;
  border-radius: 0 !important;
  top: 0;
  left: 0;
}
.about-img-main {
  padding: 0;
  text-align: left;
  margin-top: -0px;
}
.about-us-main .heading-lines.text-center {
margin-bottom: 90px;
}
.about-us-text {
  padding: 40px 15px;
  border-bottom: 0px;
  top: 0px;
}
.choose-us .choose-card-icons {
display: none;
}
.price-card {
padding: 35px 25px;
background-color: #dbd3bd;
border-radius: 15px;
}
.plan-price {
position: relative;
left: 0;
background-color: #26658c;
border-top-right-radius: 40px;
border-bottom-right-radius: 40px;
padding: 16px 36px 16px 40px;
display: inline-flex;
margin: 36px 0px 20px -27px;
}
.price-main .price-list-main {
padding-top: 20px;
flex-wrap: nowrap;
align-items: center;
}
.price-main .price-list-main * 
{
width:auto;

}
.price-card .price-buy-btn .btn.btn-primary 
{
position:relative;
font-size:24px;
}
.faq-card-main .faq-card .accordion-item h2 .accordion-button {
border-radius: 0;
}

.footer-link .nav-item .nav-link {
  margin-left: 0;
  font-size: 16px;
  font-family: barlow-semi-bold;
  padding: 0px 10px;
}

.footer-link {
display: flex;
justify-content: center;
padding: 20px 0px 20px;
flex-wrap: wrap;
}
.about-img-main .img-fluid {
  margin-top: -51px;
}
#GFG .modal-dialog.modal-lg .modal-content .popup-card
{
  margin-bottom: 30px;;
}
#GFG .account-container .input-group.username-div .input-group select#domain 
{
  width: 127px;
}

}




@media (min-width:340px) and (max-width:767px){
  .banner .row {
      flex-direction: column-reverse;
    }
    .banner .banner-text {
      padding-right: 0px;
      padding-top: 39px;
      text-align: center;
    }
    .features-card-main .col-xs-12.col-md-4 {
      width: 100%;
    }
    .welcome-back .heading-lines h2 span .lines-box {
      position: absolute;
      /* bottom: -60px; */
      justify-content:left;
      text-align:left;
      display: grid;
    }
    .lines {
      display: block;
    }
    .welcome-back {
      padding: 25px;
      position: relative;
  }
  .welcome-back .col-md-6 {
    position: initial;
  }
  .login-form {
    padding-bottom: 110px;
  }
  .welcome-back .btn.btn-primary {
    margin-top: 0;
    position: absolute;
    bottom: 50px;
    z-index: 99;
  }
  .heading-lines.text-center {
    margin-bottom: 40px;
  }
}


@media (max-width:575px)
{
.row.benifit-card-main .col-xs-12.col-sm-12.col-md-4 {
  width: 100%;
  margin-top: 20px !important;
}
 .row.price-card-main .col-xs-12.col-sm-12.col-md-4 {
  width: 100%;
  margin-bottom: 35px;
}
.price-card {
  margin: 0;
}
h2 
{
  margin-bottom:35px;
}
.plan-price h2 {
margin-bottom: 0;
}
.footer .footer-text {
padding: 20px 0% 20px;
}
.about-img-main {
  padding: 0;
  text-align: left;
  margin-top: 10px;
  padding: 0px 15px;
}
.about-img-main .img-fluid {
  margin-top: -31px;
  height: 325px;
  object-fit: cover;
}
.welcome-back h2 br {
  display: none;
}
.welcome-back h2 {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}
.welcome-back .heading-lines h2 span .lines-box {
  justify-content: center;
  text-align: center;
  display: grid;
}
#GFG .modal-dialog.modal-lg .modal-content .modal-body {
  padding: 0 14px;
}


#GFG .modal-dialog.modal-lg {
  width: 992px;
  max-width: 100%;
  margin: 0;
}

#renew_btn {
  padding: 300px;
}





}